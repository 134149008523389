<script>
  // Extensions
  import View from '@/views/View'

  // Mixins
  import LoadSections from '@/mixins/load-sections'

  export default {
    name: 'Hotels',

    metaInfo: { title: 'HOTELS' },

    extends: View,

    mixins: [
      LoadSections([
        'hero-alt',
        'Hotel',
        'contact-us',
      ]),
    ],

    props: {
      id: {
        type: String,
        default: 'hotels',
      },
    },
  }
</script>
